<style>
.img-fluid {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.carousel-inner {
  width: 100%;
  height: 100%;
}

.brand-logo {
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  height: 160px;
  background-color: #fff !important;
  /* box-shadow: 0 0 18px 3px #000; */
}
</style>
<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <!-- <vuexy-logo /> -->
        <!-- <h2 class="brand-text text-primary ml-1">Vuexy</h2> -->
        <b-img
          :src="require('@/assets/images/logo/ngerplogo.png')"
          alt="logo"
          width="240px"
          height="135px"
        />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        style="width: 100%; height: 100%"
        class="p-0 d-none d-lg-flex align-items-center"
      >
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center"
          style="width: 100%; height: 100%"
        >
          <b-carousel
            style="width: 100%; height: 100%"
            id=" carousel-interval"
            controls
            indicators
            :interval="3000"
          >
            <b-carousel-slide
              v-for="itemImage in banner"
              :key="itemImage.id"
              :img-src="imagePath + itemImage"
              style="width: 100%; height: 100%"
            />
          </b-carousel>
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1 font-weight-bold" title-tag="h2">
            Welcome to Nestoria Group! 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Please sign-in to your account and start the adventure
          </b-card-text>

          <!-- <b-alert variant="primary" show>
            <div class="alert-body font-small-2">
              <p>
                <small class="mr-50"
                  ><span class="font-weight-bold">Admin:</span> admin@demo.com |
                  admin</small
                >
              </p>
              <p>
                <small class="mr-50"
                  ><span class="font-weight-bold">Client:</span> client@demo.com
                  | client</small
                >
              </p>
            </div>
            <feather-icon
              v-b-tooltip.hover.left="'This is just for ACL demo purpose'"
              icon="HelpCircleIcon"
              size="18"
              class="position-absolute"
              style="top: 10; right: 10"
            />
          </b-alert> -->

          <!-- form -->
          <validation-observer ref="loginForm">
            <b-form class="auth-login-form mt-2" @submit.prevent="login">
              <!-- email -->
              <b-form-group label="Email/Mobile/ID" label-for="login-email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <b-link :to="{ name: 'auth-forgot-password' }">
                    <small>Forgot Password?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Role" label-for="role">
                <validation-provider
                  #default="{ errors }"
                  name="role"
                  vid="role"
                  rules="required"
                >
                  <v-select
                    v-model="role"
                    label="name"
                    :options="rolesOptions"
                    placeholder="None"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox v-model="rememberMe" @change="handelCheckbox($event)">
                  Remember Me
                </b-form-checkbox>
              </b-form-group>
              <!-- submit buttons -->
              <b-button type="submit" variant="primary" block>
                <!-- :disabled="invaalid" -->
                Sign in
              </b-button>
            </b-form>
          </validation-observer>

          <!-- <b-card-text class="text-center mt-2">
            <span>New on our platform? </span>
            <b-link :to="{ name: 'auth-register' }">
              <span>&nbsp;Create an account</span>
            </b-link>
          </b-card-text> -->

          <!-- divider -->
          <!-- <div class="divider my-2">
            <div class="divider-text">or</div>
          </div> -->

          <!-- social buttons -->
          <!-- <div class="auth-footer-btn d-flex justify-content-center">
            <b-button variant="facebook" href="javascript:void(0)">
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button variant="twitter" href="javascript:void(0)">
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button variant="google" href="javascript:void(0)">
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button variant="github" href="javascript:void(0)">
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div> -->
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import axios from "axios";
import VueCookies from "vue-cookies";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BCarousel,
  BCarouselSlide,
  BButton,
  BAlert,
  BModal,
  VBTooltip,
} from "bootstrap-vue";
import useJwt from "@/auth/jwt/useJwt";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";
import vSelect from "vue-select";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    vSelect,
    BRow,
    BCol,
    BModal,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    BCarousel,
    BCarouselSlide,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      rememberMe: false,
      visible: false,
      status: false,
      password: "",
      userEmail: "",
      imagePath: process.env.VUE_APP_IMAGE_PATH,
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      logo: require("@/assets/images/logo/ngerplogo.png"),

      // validation rules
      required,
      banner: [],
      baseApi: process.env.VUE_APP_APIENDPOINT,
      email,
      rolesOptions: ["Employee", "Channelpartner", "Franchise", "Admin"],
      role: "",
    };
  },
  created() {
    const rememberedUsername = VueCookies.get("username");
    const rememberedPassword = VueCookies.get("password");

    if (rememberedUsername && rememberedPassword) {
      this.userEmail = rememberedUsername;
      this.password = rememberedPassword;
      this.rememberMe = true;
    }
  },
  mounted() {
    this.getBanner();
    // this.getRoles();
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
    logoUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.logo = require("@/assets/images/logo/ngerplogo.png");
        return this.logo;
      }
      return this.logo;
    },
  },
  methods: {
    handelClickAgreement(value) {
      if (value == "decline") {
        this.visible = false;
        this.rememberMe = false;
        this.status = false;
      } else if (value == "accept") {
        this.visible = false;
        this.rememberMe = true;
        this.status = true;
      }
    },
    handelCheckbox(e) {
      this.visible = this.rememberMe;
      // this.rememberMe =false
    },
    async getBanner() {
      await axios({
        method: "GET",
        url: `${this.baseApi}/auth/banners`,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((json) => {
          const data = json.data.data.data;
          data.map((item) => {
            if (item.position == "Login Page") {
              this.banner.push(item.image);
            }
          });
        })
        .catch((error) => console.log(error, "error"));
      if (JSON.stringify(this.banner) == "[]") {
        this.banner.push(this.imgUrl);
      }
    },
    async getRoles() {
      await axios({
        method: "GET",
        url: `${this.baseApi}/auth/getPosition`,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((json) => {
          this.rolesOptions = json.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    login() {
      this.$refs.loginForm.validate().then((success) => {
        if (success) {
          useJwt
            .login({
              username: this.userEmail,
              password: this.password,
              role: this.role,
            })
            // .catch((error) => {
            //   console.log(error, "error-");
            //   alert("Please Enter a Valid Password");
            //   this.$refs.loginForm.setErrors(error.response.data.error);
            // })
            .then((response) => {
              if (response.data.token) {
                const { user } = response.data;
                useJwt.setToken(response.data.token);
                useJwt.setRefreshToken(response.data.token);
                localStorage.setItem("userData", JSON.stringify(user));
                this.$ability.update([
                  {
                    action: "manage",
                    subject: "all",
                  },
                ]);
                this.$router.replace(getHomeRouteForLoggedInUser(user.role));
                if (this.rememberMe) {
                  VueCookies.set("username", this.userEmail, "30d"); // Store the username for 30 days
                  VueCookies.set("password", this.password, "30d"); // Store the password for 30 days (Note: Not recommended for actual password storage)
                } else {
                  VueCookies.remove("username");
                  VueCookies.remove("password");
                }
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Welcome ${user.name || user.name}`,
                    icon: "CoffeeIcon",
                    variant: "success",
                    text: `You have successfully logged in as ${user.role}. Now you can start to explore!`,
                  },
                });
                location.reload();
              } else {
                if (response.data.status == 404) {
                  alert(response.data.message);
                  // this.$refs.loginForm.setErrors(response.data.message);
                } else {
                  alert("Your Acoount has been Blocked Plese Contact Admin ");
                  // this.$refs.loginForm.setErrors(response.data.message);
                }
              }
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
